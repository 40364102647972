.jkw-quick_setting-view-jizuo {
  margin-right: 8rem !important;
  padding-right: 8rem !important;
  width: 45rem !important;
}
.jkw-mapping-view-jizuo {
  width: 79.5rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.jkw-jizuo-param-list-view {
  height: 100%;
  display: block;
  overflow: scroll;
}
.pxn-slide-row-view {
  width: 67.4rem;
  height: 5.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.pxn-slide-row-view .pxn-slide-row-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pxn-slide-row-view .pxn-slide-row-top-view .pxn-slide-row-top-left-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pxn-slide-row-view .pxn-slide-row-top-view .pxn-slide-row-top-right-view {
  width: 5rem;
  height: 2.2rem;
  background: #FFF4EF;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #ED7020;
}
.pxn-slide-row-view .pxn-slide-row-center-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.pxn-slide-row-view .pxn-slide-row-center-view .pxn-slide-row-center-wrap-view {
  width: 5rem;
  height: 2.2rem;
  /* 纯白色 */
  background: #FFFFFF;
  /* 背景色 */
  border: 0.1rem solid #F5F5F5;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
  font-size: 1.2rem;
}
.jizuo-font-1 {
  color: #0C0C0C;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
}
.pxn-slider-view {
  flex: 1;
  height: 1rem;
  padding: 0rem 0rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.pxn-slider-view .ant-slider-track {
  height: 100% !important;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%) !important;
  border-radius: 2.2rem 0rem 0rem 2.2rem !important;
}
.pxn-slider-view .ant-slider-rail {
  height: 100% !important;
  border-radius: 1.7rem !important;
  background: #FFF4EF !important;
}
.pxn-slider-view .ant-slider-step {
  height: 100% !important;
}
.pxn-slider-view .ant-slider-handle {
  width: 1.8rem !important;
  height: 1.8rem !important;
  border: none !important;
  margin-top: -0.5rem !important;
  background: linear-gradient(270deg, #FFB54A 0%, #FF731F 100%);
}
.margin-right-6 {
  margin-right: 0.6rem;
}
.margin-bottom-50 {
  margin-bottom: 5rem;
}
.jkw-pop-over-view {
  max-width: 30rem;
  padding: 1rem 2rem ;
  /* 纯白色 */
  background: #FFFFFF;
  /* 背景色 */
  border: 0.1rem solid #F5F5F5;
  border-radius: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
  font-size: 1.2rem;
}
