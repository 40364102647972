.jkw-quick_setting-view-shift-lever {
  margin-right: 5rem !important;
  padding-right: 5rem !important;
  width: 41.6rem !important;
}
.jkw-wheel-view-shift-lever {
  width: 74.4rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
  box-sizing: border-box !important;
  padding: 2rem !important;
  position: relative !important;
}
.jkw-shift-lever-top-view {
  /* 主色 */
  width: 100%;
  height: 21rem;
  box-sizing: border-box;
  border: 0.05rem solid #ED7020;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  position: relative;
}
.jkw-shift-lever-top-wrap-view {
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.jkw-shift-lever-mapping-row-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-shift-lever-center-view {
  position: relative;
  width: 70.4rem;
  height: 30.5rem;
  border: 0.05rem solid #ED7020;
  border-radius: 1rem;
  overflow: hidden;
}
.jkw-shift-lever-center-view .shift-lever-device-icon-3 {
  width: 100%;
  height: 100%;
}
.shift-lever-device-icon-1 {
  width: 18.6rem;
  height: 17rem;
}
.shift-lever-device-icon-2-view {
  position: relative;
  width: 18.6rem;
  height: 17rem;
}
.shift-lever-device-icon-2-view .shift-lever-device-icon-2 {
  width: 100%;
  height: 100%;
}
.jkw-shift-mapping-row-view {
  width: 16.6rem !important;
  margin-right: 1.3rem !important;
}
.jkw-shift-mapping-row-view-2 {
  width: 16.6rem !important;
  margin-right: 10.25rem !important;
}
.margin-right-0 {
  margin-right: 0rem !important;
}
.jkw-icon-52-20 {
  width: 5.2rem;
  height: 2rem;
}
.jkw-wheel-map-list-window-view {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 97;
  width: 100%;
  height: 100%;
}
.jkw-wheel-map-list-window-view .jkw-shift-map-icon-view-1 {
  border-radius: 1000%;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
}
.jkw-shift-pos-8 {
  top: 9.1rem;
  left: 3.3rem;
}
.jkw-shift-pos-9 {
  top: 8.9rem;
  left: 13.7rem;
}
.jkw-shift-pos-1 {
  top: 8rem;
  left: 25.25rem;
}
.jkw-shift-pos-2 {
  top: 21.1rem;
  left: 25.25rem;
}
.jkw-shift-pos-3 {
  top: 8rem;
  left: 31.95rem;
}
.jkw-shift-pos-4 {
  top: 21.1rem;
  left: 31.95rem;
}
.jkw-shift-pos-5 {
  top: 8rem;
  left: 38.75rem;
}
.jkw-shift-pos-6 {
  top: 21.1rem;
  left: 38.75rem;
}
.jkw-shift-pos-7 {
  top: 21.1rem;
  left: 44.8rem;
}
#shift-canvas-1 {
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 98;
}
