.jkw-upgrade-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3.5rem;
  background: #fff;
}
.jkw-upgrade-main-view {
  width: 135.5rem;
  height: 62.9rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-upgrade-main-view .ugd-column-view-1 {
  height: 100%;
  width: 42.3rem;
  background: #FBFBFB;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.jkw-upgrade-main-view .ugd-column-view-2 {
  height: 100%;
  width: 46.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.jkw-upgrade-main-view .ugd-column-view-2 .ugd-column-view-2-1,
.jkw-upgrade-main-view .ugd-column-view-2 .ugd-column-view-2-2 {
  height: 30.4rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #FBFBFB;
  border-radius: 2rem;
  position: relative;
}
.jkw-upgrade-main-view .ugd-column-view-2 .ugd-column-view-2-2 {
  height: 30.4rem !important;
}
.jkw-upgrade-main-view .ugd-column-view-3 {
  width: 42.3rem;
  height: 100%;
  background: #FBFBFB;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.jkw-upgrade-bottom-view {
  width: 51rem;
  height: 4.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid #ED7020;
  border-radius: 1rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
  color: #ED7020;
  cursor: pointer;
  margin-top: 5rem;
}
.jkw-ugd-btn-view-1 {
  width: 36.3rem;
  height: 4.4rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 1rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.jkw-ugd-btn-view-2 {
  width: 36.3rem;
  height: 4.4rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 1rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}
.jkw-ugd-btn-view-3 {
  width: 36.3rem;
  height: 4.4rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}
.jkw-ugd-btn-view-3 .jkw-ugd-btn-view-3-1 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #FFC6A2;
}
.jkw-ugd-btn-view-3 .jkw-ugd-btn-view-3-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
}
.jkw-ugd-btn-view-3 .jkw-ugd-btn-view-3-3 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-ugd-device-1 {
  width: 32.6rem;
  height: auto;
}
.jkw-ugd-device-2 {
  width: 11.5rem;
  height: 9.53rem;
}
.jkw-ugd-device-3 {
  width: 13.5rem;
  height: 9.6rem;
}
.jkw-ugd-device-4 {
  width: 25.8rem;
  height: 15.1rem;
}
.ugd-cl-1-row-view-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.ugd-cl-1-row-view-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ugd-margin-top-1 {
  margin-top: 0.6rem !important;
}
.ugd-margin-right-10 {
  margin-right: 1rem;
}
.ugd-margin-bottom-1 {
  margin-bottom: 1.6rem !important;
}
.ugd-cl-1-row-view-1 {
  display: flex;
  width: 36.3rem;
  height: 6.2rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 0.1rem solid #FFE5D9;
}
.font-ugd-font-1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 3.6rem;
  color: #ED7020;
}
.font-ugd-font-2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #ED7020;
}
.font-ugd-font-3 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #8F8F8F;
}
.font-ugd-font-4 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: #1A1A1A;
}
.font-ugd-font-5 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: center;
  color: #8F8F8F;
}
.margin-top-55 {
  margin-top: 5.5rem;
}
.margin-bottom-55 {
  margin-bottom: 5.5rem;
}
.ugd-margin-top-35 {
  margin-top: 2rem;
}
.udg-disconnect-view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0rem;
  left: 0rem;
  background: #f8f8f8;
  opacity: 0.5;
  z-index: 10;
}
