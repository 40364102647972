.jkw-quick_setting-view-footrest {
  margin-right: 5rem !important;
  padding-right: 5rem !important;
  width: 41.6rem !important;
}
.jkw-wheel-view-footrest {
  width: 75rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.foot-rest-device-icon {
  width: 20.4rem;
  height: 14.5rem;
}
.pxn-footrest-num-wrap-view {
  width: 5rem;
  height: 2.3rem;
  /* 纯白色 */
  background: #FFFFFF;
  /* 背景色 */
  border: 0.1rem solid #F5F5F5;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
  font-size: 1.2rem;
}
.pxn-footrest-num-wrap-view-2 {
  width: 5rem;
  height: 2.3rem;
  background: #FFF4EF;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  color: #ED7020;
}
.jkw-footrest-row-view {
  width: 75rem;
  height: 22.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-right: 2rem;
}
.jkw-footrest-row-view .jkw-footrest-row-left-view {
  width: 20.4rem;
  height: 19.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.jkw-footrest-row-view .jkw-footrest-row-center-view {
  width: 21.95rem;
  height: 21.15rem;
  position: relative;
}
.jkw-footrest-row-view .jkw-footrest-row-center-view .jkw-footrest-char-hint-view {
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-footrest-row-view .jkw-footrest-row-right-view {
  width: 20.05rem;
  height: 16.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.jkw-footrest-row-view .jkw-footrest-row-right-view .jkw-footrest-row-right-view-1 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-footrest-row-view .jkw-footrest-row-right-view .jkw-footrest-row-right-view-2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
}
.jkw-footrest-row-view .jkw-footrest-row-right-view .jkw-footrest-row-right-view-3 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-footrest-row-view .jkw-footrest-row-right-view .jkw-footrest-row-right-view-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  background: #F8F9FB;
  border-radius: 1rem;
  cursor: pointer;
}
.jkw-footrest-font-1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.5rem;
  text-align: center;
  color: #1A1A1A;
}
.jkw-footrest-border-bottom-05 {
  border-bottom: 0.05rem solid #CCCCCC;
}
.jkw-footrest-slider-view {
  width: 14rem;
  height: 1rem;
  border-radius: 2.2rem;
  position: relative;
}
.jkw-footrest-slider-view .jkw-footrest-slider-view-1 {
  background: #FFF4EF;
  border-radius: 2.2rem;
  width: 100%;
  height: 1rem;
  z-index: 1;
}
.jkw-footrest-slider-view .jkw-footrest-slider-view-2 {
  /* 渐变 */
  width: 11.6rem;
  height: 1rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 2.2rem;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}
.jkw-footrest-font-2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin-left: 0.2rem;
  /* 主色 */
  color: #ED7020;
}
.jkw-footrest-font-5 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ED7020;
}
.margin-right-5 {
  margin-right: 0.5rem;
}
.jkw-footrest-char-view {
  width: 20rem !important;
  height: 20rem !important;
}
.jkw-footrest-row-right-view-1-wrap {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.jkw-footrest-row-right-view-1-wrap .jkw-footrest-slider-view-old {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-footrest-row-right-view-1-wrap .jkw-footrest-slider-view-old .jkw-footrest-slider-view-old-slider {
  width: 6.7rem;
  height: 0.4rem;
  background: #FFF4EF;
  border-radius: 2.2rem;
}
.margin-left-4 {
  margin-left: 0.4rem;
}
