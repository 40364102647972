.jkw-header-view {
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5.4rem;
}
.jkw-header-view .jkw-header-right-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.jkw-logo-icon {
  width: 6.6rem;
  height: 2.844rem;
  margin-left: 2rem;
}
.jkw-point-cursor {
  cursor: pointer !important;
}
.margin-right-28 {
  margin-right: 2.8rem;
}
.margin-right-10 {
  margin-right: 1rem;
}
.jkw-nav-view {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-nav-view .jkw-menu-view,
.jkw-nav-view .jkw-menu-active-view {
  min-width: 7.6rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.jkw-nav-view .jkw-menu-active-view {
  position: relative;
  box-sizing: border-box;
}
.jkw-nav-view .jkw-menu-active-view .jkw-menu-active-prefix-view {
  position: absolute;
  bottom: 0;
  height: 0.2rem;
  width: 100%;
  border-radius: 3.2rem;
  background-color: #E56F35;
  overflow: hidden;
}
.jkw-nav-view .jkw-menu-active-view .jkw-menu-active-prefix-view2 {
  position: absolute;
  bottom: 0;
  height: 0.2rem;
  width: 100%;
  border-radius: 3.2rem;
  background-color: #f8f8f8;
  overflow: hidden;
}
.jkw-nav-view .jkw-menu-view {
  cursor: pointer ;
}
.margin-top-28 {
  margin-top: 2.8rem;
}
.pxn-font-1 {
  color: #ED7020;
  font-size: 1.4rem;
}
.pxn-font-2 {
  color: #8F8F8F;
  font-size: 1.4rem;
}
.margin-right-6 {
  margin-right: 0.6rem;
}
.margin-left-30 {
  margin-left: 3rem;
}
.jkw-header-right-view img:hover {
  background: #f8f8f8;
}
