.jkw-hexcolorpicker-wrap-view {
  position: absolute;
  z-index: 11;
  box-shadow: 0.9rem 0.8rem 1.4rem rgba(0, 0, 0, 0.25);
  top: -31rem;
  left: -11rem;
}
.jkw-hexcolorpicker-wrap-view .jkw-hexcolorpicker-view {
  cursor: pointer;
  width: 27rem !important;
  height: 30rem !important;
}
.jkw-icon-66-29 {
  width: 3.55rem;
  height: auto;
}
.jkw-wheel-view {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-wheel-view .jkw-left-view {
  width: 100%;
  height: 72rem;
  box-sizing: border-box;
  padding: 4rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view {
  height: 70.7rem;
  width: 40rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 0.1rem solid #eaeaea;
  box-sizing: border-box;
  padding-right: 3rem;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view {
  width: 100%;
  height: 3rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-left-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right-view .jkw-quick_setting-top-row-view,
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right-view .jkw-quick_setting-top-row-active-view {
  width: 6.8rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #8f8f8f;
  border: 0.05rem solid #ccc;
  border-right: none ;
  border-radius: 0.6rem 0rem 0rem 0.6rem;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right-view .jkw-quick_setting-top-row-active-view {
  color: #ED7020 !important;
  border: 0.05rem solid #ED7020 !important;
  border-radius: 0rem 0.6rem 0.6rem 0rem !important;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right2-view {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right2-view .jkw-quick_setting-top-row-view,
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right2-view .jkw-quick_setting-top-row-active-view {
  width: 6.8rem;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #8f8f8f;
  border: 0.05rem solid #ccc;
  border-radius: 0rem 0.6rem 0.6rem 0rem;
  border-left: none ;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-top-view .jkw-quick_setting-top-right2-view .jkw-quick_setting-top-row-active-view {
  color: #ED7020 !important;
  border: 0.05rem solid #ED7020 !important;
  border-radius: 0.6rem 0rem 0rem 0.6rem !important;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view::-webkit-scrollbar {
  display: none;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view {
  width: 100%;
  height: 20rem;
  flex: 1;
  flex-wrap: wrap;
  overflow: scroll;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view .jkw-quick_setting-row-view,
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view .jkw-quick_setting-row-active-view {
  height: 4.4rem;
  width: 100%;
  background: #F8F9FB;
  border-radius: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view .jkw-quick_setting-row-active-view {
  /* 主色 */
  border: 0.1rem solid #ED7020 !important;
  color: #ED7020 !important;
  background: #FFFFFF !important;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view .jkw-quick_setting-row-active-view .jkw-quick_setting-row-active-wrap-view {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #ED7020 !important;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-center-view .jkw-quick_setting-row-active-view .jkw-map-del-icon-view {
  width: 2rem;
  height: 2.4rem;
  cursor: pointer;
  box-sizing: content-box;
  padding: 1rem;
  padding-right: 0rem;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-down-view {
  /* 渐变 */
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.5rem;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-down-mode2-view {
  /* 渐变 */
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
}
.jkw-wheel-view .jkw-left-view .jkw-quick_setting-view .jkw-quick_setting-down-mode1-view {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #ED7020;
  border: 0.1rem solid #ED7020;
  cursor: pointer;
}
.jkw-wheel-view .jkw-right-view {
  width: 6rem;
  height: 100%;
  background: #FFFFFF;
  box-shadow: -0.2rem 0.2rem 0.9rem rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.margin-top-40 {
  margin-top: 4rem;
}
.jkw-point-view {
  cursor: pointer;
}
.jkw-font-1 {
  font-size: 1.4rem;
  color: #1a1a1a;
  font-weight: 550;
  font-family: 'PingFang SC';
}
.jkw-font-2 {
  font-size: 1.4rem;
  color: #1a1a1a;
  font-weight: 400;
}
.margin-left-6 {
  margin-left: 0.6rem;
}
.jkw-mapping-view {
  width: 89.5rem;
  height: 70.7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.jkw-mapping-view .jkw-mapping-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.8rem;
}
.jkw-mapping-view .jkw-mapping-center-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-mapping-view .jkw-mapping-center-view .jkw-mapping-center-main-view {
  width: 34.7rem;
  height: 32.2rem;
  position: relative;
}
.jkw-mapping-view .jkw-mapping-center-view .jkw-mapping-center-main-view .jkw-wheel-icon-view {
  width: 100%;
  height: 100%;
}
.jkw-mapping-view .jkw-mapping-down-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.7rem;
}
.jkw-mapping-view .jkw-led-view {
  z-index: 109;
  width: 100%;
  height: 5rem;
  border: 0.1rem solid #F8F9FB;
  border-radius: 3rem;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  position: relative;
}
.jkw-mapping-view .jkw-led-view .jkw-led-list-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
}
.jkw-mapping-view .jkw-led-view .jkw-led-list-view .jkw-led-out-view {
  width: 3rem;
  height: 3rem;
  background: #FFFFFF;
  border: 0.05rem solid #CCCCCC;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 3.4rem;
  cursor: pointer !important;
}
.jkw-mapping-view .jkw-led-view .jkw-led-list-view .jkw-led-inner-view {
  border-radius: 100%;
  width: 2.4rem;
  height: 2.4rem;
}
.jkw-mapping-view .jkw-led-view .jkw-led-control-view {
  width: 22.2rem;
  height: 3rem;
  background: #F8F9FB;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-mapping-view .jkw-led-view .jkw-led-control-view .jkw-led-bt-active-view,
.jkw-mapping-view .jkw-led-view .jkw-led-control-view .jkw-led-bt-view {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
  font-size: 1.2rem;
  border-radius: 2.4rem;
  font-weight: 400;
  cursor: pointer;
}
.jkw-mapping-view .jkw-led-view .jkw-led-control-view .jkw-led-bt-active-view {
  /* 渐变 */
  font-weight: 500;
  color: #fff !important;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%) !important;
}
.margin-right-none {
  margin-right: 0rem !important;
}
.margin-left-12 {
  margin-left: 1.2rem;
}
.margin-right-12 {
  margin-right: 1.2rem;
}
.margin-right-29 {
  margin-right: 2.9rem;
}
.margin-top-20 {
  margin-top: 2rem;
}
.jkw-mapping-font-1-view {
  width: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  font-size: 1.3rem;
  color: #1a1a1a;
  font-family: 'PingFang SC';
}
.jkw-mapping-font-2-view {
  /* 主色 */
  width: auto;
  height: 2.4rem;
  color: #ED7020;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 550;
  font-size: 1.3rem;
  line-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-mapping-font-3-view {
  width: 4.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 550;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: #1a1a1a;
  font-family: 'PingFang SC';
}
.jkw-mapping-font-4-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.3rem;
  color: #fff;
  font-family: 'PingFang SC';
}
.jkw-mapping-font-5-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: #fff;
  font-family: 'PingFang SC';
}
.jkw-mapping-row-view,
.jkw-mapping-row-active-view {
  height: 4.4rem;
  width: 20.05rem;
  background: #FBFBFB;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border: 0.2rem solid #FBFBFB !important;
  z-index: 0;
}
.jkw-mapping-row-active-view {
  border: 0.2rem solid #ED7020 !important;
  z-index: 99 !important;
}
.jkw-mapping-row-active-view .jkw-mapping-row-wrap-view {
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%) !important;
}
.jkw-mapping-row-wrap-view {
  width: 14.9rem;
  margin-right: 0.1rem;
  height: 3.6rem;
  background: #FFFFFF;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-mappint-center-wrap-down-view {
  margin-top: 4.7rem;
}
.jkw-mapping-font-1 {
  font-size: 1.3rem;
  color: #fff;
  font-family: 'PingFang SC';
}
.jkw-mapping-font-wrap-view {
  padding-right: 0.6rem;
  flex: 1;
  height: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-right: 0.05rem solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}
.jkw-mapping-font-wrap2-view {
  width: 100%;
  height: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.jkw-mapping-input-icon-wrap-view {
  cursor: pointer;
  width: 2.4rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 0.4rem;
  box-sizing: border-box;
}
.jkw-mapping-input-icon {
  width: 1rem;
  height: 1rem;
}
.jkw-wheel-map-list-window-view {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 97;
  width: 100%;
  height: 100%;
}
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-1,
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-2,
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-3,
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-4 {
  border-radius: 1000%;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
}
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-3 {
  width: 3.3rem !important;
  height: 3.3rem !important;
}
.jkw-wheel-map-list-window-view .jkw-wheel-map-icon-view-4 {
  width: 2.7rem !important;
  height: 2.7rem !important;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-2 {
  top: 12.15rem;
  left: 7.65rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-3 {
  top: 12.9rem;
  left: 10.45rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-4 {
  top: 12.9rem;
  right: 10.05rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-6 {
  top: 14.5rem;
  left: 7.7rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-7 {
  left: 16.2rem;
  top: 16rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-8 {
  right: 7.25rem;
  top: 12.15rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-11 {
  top: 14.5rem;
  left: 0.4rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-12 {
  top: 21rem;
  left: 0.8rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-13 {
  right: 8.25rem;
  top: 14rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-14 {
  right: 6.5rem;
  top: 14.85rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-15 {
  top: 14.5rem;
  right: 0.4rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-16 {
  top: 21rem;
  right: 0.8rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-17 {
  top: 21.2rem;
  left: 11.7rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-19 {
  top: 21.2rem;
  right: 11.4rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-21 {
  top: 19.1rem;
  left: 8.55rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-24 {
  top: 19.1rem;
  right: 8.15rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-25 {
  top: 21.2rem;
  left: 8.55rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-26 {
  top: 20.15rem;
  left: 10.45rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-27 {
  top: 20.15rem;
  right: 10.05rem;
}
.jkw-wheel-map-list-window-view .jkw-map-pos-28 {
  top: 21.25rem;
  right: 8.2rem;
}
#pxn_map {
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 98;
}
.z-index-99 {
  z-index: 99 !important;
}
.jkw-quick_setting-down-mode3-view {
  /* 渐变 */
  width: 11.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: #fff;
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #ED7020;
  border: 0.1rem solid #ED7020;
  cursor: pointer;
}
.jkw-quick_setting-down-mode4-view {
  width: 11.5rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}
.opacity-5 {
  opacity: 0.5 !important;
  cursor: auto !important;
}
.flex-center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.jkw-display-none-icon-view {
  width: 12rem;
  height: 15.7rem;
}
.tool-popover-view-0 {
  width: 24rem;
  height: 16.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tool-popover-view-2 {
  width: 26rem;
  height: 28.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-popover-inner {
  background: #fff !important;
  box-shadow: 0rem 0.1rem 1.1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 1rem !important;
  overflow: hidden !important;
}
.ant-popover-inner-content {
  padding: 0rem 0rem !important;
}
.tool-font-1 {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: 'PingFang SC';
  font-style: normal;
  line-height: 2.5rem;
  color: #1A1A1A;
}
.tool-font-2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #8F8F8F;
}
.margin-top-5 {
  margin-top: 0.5rem;
}
.tool-popover-switch-view {
  margin-top: 2rem;
  width: 18rem;
  height: 3.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #F8F9FB;
  border-radius: 3rem;
}
.tool-popover-switch-view .tool-popover-switch-select-view {
  /* Linear */
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 3rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #FFFFFF;
}
.tool-popover-switch-view .tool-popover-switch-unselect-view {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #8F8F8F;
  cursor: pointer;
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
.tool-wheel-jiaozhun-pic-view {
  width: 15rem;
  height: 18.1rem;
  position: relative;
}
.tool-wheel-jiaozhun-pic-view .wheel-jiaozhun-view {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.tool-wheel-jiaozhun-pic-view .wheel-jiaozhun-animation {
  animation: rotation 1s linear infinite;
}
.tool-wheel-jiaozhun-pic-view .wheel-jiaozhun-bg-view {
  width: 100%;
  height: 100%;
}
.tool-btn-1 {
  /* 渐变 */
  width: 20rem;
  height: 3.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: #fff;
  margin-top: 1rem;
  cursor: pointer;
}
.tool-popover-view-3 {
  width: 22rem;
  height: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.tool-font-3 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: #1A1A1A;
  margin-bottom: 1rem;
  margin-top: 3rem;
}
#jizuo-heat-canvas {
  width: 13rem !important;
  height: 13rem !important;
}
.jizuo-heat-icon-view {
  width: 4rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #E0E0E0;
  border-radius: 1.4rem;
  overflow: hidden;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: #FFFFFF;
}
