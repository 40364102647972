.jkw-jizuo-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 2rem;
}
.jkw-setting-control-view {
  min-width: 33rem;
  height: 4.2rem;
  background: #F8F9FB;
  border-radius: 2.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.jkw-setting-control-view .jkw-setting-bt-active-view,
.jkw-setting-control-view .jkw-setting-bt-view,
.jkw-setting-control-view .jkw-setting-bt-active-view2,
.jkw-setting-control-view .jkw-setting-bt-view2 {
  flex: 1;
  min-width: 11rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #8F8F8F;
  font-size: 1.6rem;
  border-radius: 2.4rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0 2rem ;
  box-sizing: border-box;
}
.jkw-setting-control-view .jkw-setting-bt-active-view2,
.jkw-setting-control-view .jkw-setting-bt-view2 {
  flex: auto;
  width: auto;
}
.jkw-setting-control-view .jkw-setting-bt-active-view,
.jkw-setting-control-view .jkw-setting-bt-active-view2 {
  /* 渐变 */
  font-weight: 500;
  color: #fff !important;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%) !important;
}
.margin-bottom-35 {
  margin-bottom: 3.5rem;
}
.jkw-setting-form-row-view {
  width: auto;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 18rem;
}
.jkw-setting-form-row-view .jkw-form-label-view {
  width: 28rem;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 6rem;
}
.jkw-setting-font-1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #1A1A1A;
}
.jkw-setting-font-2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #8F8F8F;
}
.jkw-form-content-view {
  width: auto;
  height: 4.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-setting-btn-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 4.4rem;
  width: 33rem;
  height: 4.2rem;
  /* 主色 */
  box-sizing: border-box;
  border: 0.1rem solid #ED7020;
  border-radius: 0.6rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  color: #ED7020;
  cursor: pointer;
}
.jkw-setting-btn-view-2 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #8F8F8F;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 4.4rem;
  width: 33rem;
  height: 4.2rem;
  border: 0.1rem solid #CCCCCC;
  border-radius: 0.6rem;
}
