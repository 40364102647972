.jkw-main-info-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.jkw-main-info-view .lv1-left-top-view {
  width: 144rem;
  height: 9rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.jkw-main-info-view .lv2-main-view {
  width: 144rem;
  flex: 1;
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.jkw-main-info-view .lv2-main-view .lv2-left-view {
  background-color: #fff;
  width: 69.1rem;
  height: 65.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2rem;
  margin-right: 1rem;
  padding: 0  3rem ;
  position: relative;
}
.jkw-main-info-view .lv2-main-view .lv2-right-view {
  width: 69rem;
  height: 65.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.jkw-main-info-view .lv2-main-view .lv2-right-view .lv3-right-top-view {
  width: 100%;
  height: 40.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-main-info-view .lv2-main-view .lv2-right-view .lv3-right-top-view .lv2-right-top-view,
.jkw-main-info-view .lv2-main-view .lv2-right-view .lv3-right-top-view .lv2-right-top-view-2 {
  background-color: #fff;
  width: 32.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2rem;
  padding: 0  3rem ;
  position: relative;
}
.jkw-main-info-view .lv2-main-view .lv2-right-view .lv3-right-top-view .lv2-right-top-view-2 {
  width: 35.4rem !important;
}
.jkw-main-info-view .lv2-main-view .lv2-right-view .lv2-right-dowm-view {
  margin-top: 1rem;
  background-color: #fff;
  width: 100%;
  height: 24.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2rem;
  padding: 0  3rem ;
  position: relative;
}
.lv2-right-top-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  border-bottom: 0.05rem solid rgba(237, 108, 0, 0.2);
}
.jkw-font-1 {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.9);
}
.jkw-font-2 {
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.9);
}
.jkw-btn-1 {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background: rgba(237, 108, 0, 0.05);
  color: rgba(237, 108, 0, 0.6);
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.jkw-home-device-pic-2 {
  width: 17rem;
  height: 10rem;
}
.jkw-home-device-pic-3 {
  width: 19.7rem;
  height: 14rem;
}
.jkw-device-pic-4 {
  width: 28.85rem;
  height: 12.5rem;
  border: 0.1rem solid #ED7020;
  border-radius: 1rem;
  box-sizing: border-box;
}
.jkw-banner-icon {
  width: 100%;
  height: 100%;
}
#home-jizuo-heat-canvas {
  width: 15rem !important;
  height: 15rem !important;
}
.margin-bottom-20 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.jkw-home-btn-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 26.6rem;
  height: 4rem;
  background: linear-gradient(92.4deg, #FFB54A 1.2%, #FF721E 99.25%);
  border-radius: 1.2rem;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}
.jkw-home-jizuo-wrap-view {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
}
.margin-top--5 {
  margin-top: -1rem;
}
.margin-right-25 {
  margin-right: 2.5rem;
}
.jkw-home-shift-info-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-home-shift-info-view .jkw-home-shift-detail-view {
  width: 40.8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jkw-home-shift-info-view .jkw-home-shift-row-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-home-font-1 {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #1A1A1A;
}
.margin-bottom-30 {
  margin-bottom: 3rem;
}
.margin-right-7 {
  margin-right: 0.7rem;
}
.margin-right-10 {
  margin-right: 1rem;
}
.slider-width-231 {
  width: 23rem !important;
}
.jkw-width-30 {
  width: 3rem !important;
}
.jkw-icon-52-57 {
  width: 5.2rem;
  height: 5.7rem;
}
.home-gear-row-view-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.home-gear-row-view-2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.8rem;
  margin-top: 1rem;
  box-sizing: border-box;
}
.home-gear-row-view-3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.8rem;
}
.home-map-view-1,
.home-map-view-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  box-sizing: border-box;
  width: 12.4rem;
  height: 4rem;
  border: 0.1rem solid #F8F9FB;
  /* 背景使用中 */
  background: #F8F9FB;
  border-radius: 1rem;
}
.home-map-view-1 .home-map-content-view-1,
.home-map-view-2 .home-map-content-view-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* 白色 */
  background: #FFFFFF;
  border-radius: 1.2rem;
}
.home-map-view-1 {
  width: 7rem !important;
  height: 3.6rem !important;
  z-index: 0 ;
}
.home-gear-row-all-view {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-map-active-view {
  background: #FFFFFF !important;
  border: 0.1rem solid #ED7020 !important;
  z-index: 99 !important;
  /* 渐变 */
}
.home-map-active-view .home-map-content-view-1 {
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%) !important;
  color: #FFFFFF !important;
}
.margin-bottom-32-5 {
  margin-bottom: 3.257rem;
}
.jkw-icon-3546-13 {
  width: 3.546rem;
  height: 1.293rem;
}
.home-wheel-view {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.home-wheel-row-view-1 {
  width: auto;
  height: 54.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.home-wheel-row-view-2 {
  height: 54.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.home-wheel-row-view-2 .home-wheel-bottom-wrap-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-menu-pos-parent-view {
  position: absolute;
  width: 7rem;
  height: 7rem;
  border-radius: 100%;
  left: 1.5rem;
  top: 0.05rem;
  z-index: 1;
}
.home-menu-pos-parent-view .home-menu-pos-view,
.home-menu-pos-parent-view .home-menu-pos-view-1,
.home-menu-pos-parent-view .home-menu-pos-view-2,
.home-menu-pos-parent-view .home-menu-pos-view-3,
.home-menu-pos-parent-view .home-menu-pos-view-4,
.home-menu-pos-parent-view .home-menu-pos-view-5,
.home-menu-pos-parent-view .home-menu-pos-view-6,
.home-menu-pos-parent-view .home-menu-pos-view-7,
.home-menu-pos-parent-view .home-menu-pos-view-8 {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  background: #ED7020;
  border-radius: 100%;
  z-index: 2;
}
.home-menu-pos-parent-view .home-menu-pos-view {
  left: 2.9rem;
  top: 2.9rem;
}
.home-menu-pos-parent-view .home-menu-pos-view-1 {
  left: 2.9rem;
  top: 0rem ;
}
.home-menu-pos-parent-view .home-menu-pos-view-2 {
  left: 4.9rem;
  top: 1rem ;
}
.home-menu-pos-parent-view .home-menu-pos-view-3 {
  right: 0rem ;
  top: 2.9rem;
}
.home-menu-pos-parent-view .home-menu-pos-view-4 {
  left: 4.9rem;
  bottom: 1rem ;
}
.home-menu-pos-parent-view .home-menu-pos-view-5 {
  left: 2.9rem;
  bottom: 0rem;
}
.home-menu-pos-parent-view .home-menu-pos-view-6 {
  right: 4.9rem;
  bottom: 1rem;
}
.home-menu-pos-parent-view .home-menu-pos-view-7 {
  left: 0rem ;
  top: 2.9rem;
}
.home-menu-pos-parent-view .home-menu-pos-view-8 {
  right: 4.9rem;
  top: 1rem ;
}
.home-wheel-bottom-wrap-view-1 {
  width: 10rem;
  height: 7rem;
  position: relative;
}
.home-wheel-bottom-wrap-view-1 .jkw-home-menu-icon-1 {
  width: 100%;
  height: 100%;
}
.home-wheel-bottom-wrap-view-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 32.2rem;
}
.home-wheel-center-wrap-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.margin-right-45 {
  margin-right: 4.5rem;
}
.jkw-home-tc-icon {
  width: 9.2rem;
  height: 8rem;
}
.margin-right-101 {
  margin-right: 10.1rem;
}
.margin-top-12 {
  margin-top: 1.2rem;
}
.jkw-home-wheel-map-view {
  position: relative;
  width: 33.6rem;
  height: 33.6rem;
}
.jkw-home-wheel-map-view .jkw-home-device-pic-1 {
  width: 100% ;
  height: 100%;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 97;
  width: 100%;
  height: 100%;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-1,
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-2,
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-3,
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-4 {
  border-radius: 1000%;
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-3 {
  width: 3.3rem !important;
  height: 3.3rem !important;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-wheel-map-icon-view-4 {
  width: 2.7rem !important;
  height: 2.7rem !important;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-2 {
  top: 12.15rem;
  left: 7.5rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-3 {
  top: 12.9rem;
  left: 10.15rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-4 {
  top: 12.9rem;
  right: 9.9rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-6 {
  top: 14.3rem;
  left: 7.7rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-7 {
  left: 15.6rem;
  top: 15.6rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-8 {
  right: 7rem;
  top: 12.15rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-11 {
  top: 14.5rem;
  left: 0.4rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-12 {
  top: 21rem;
  left: 1rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-13 {
  right: 8.25rem;
  top: 13.8rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-14 {
  right: 6.5rem;
  top: 14.7rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-15 {
  top: 14.5rem;
  right: 0.4rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-16 {
  top: 21rem;
  right: 0.8rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-17 {
  top: 20.6rem;
  left: 11.4rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-19 {
  top: 20.6rem;
  right: 11.1rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-21 {
  top: 18.5rem;
  left: 8.55rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-24 {
  top: 18.4rem;
  right: 8.15rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-25 {
  top: 20.8rem;
  left: 8.4rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-26 {
  top: 19.7rem;
  left: 10.3rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-27 {
  top: 19.55rem;
  right: 9.8rem;
}
.jkw-home-wheel-map-view .jkw-home-wheel-map-list-window-view .jkw-map-pos-28 {
  top: 20.5rem;
  right: 8.1rem;
}
#home_pxn_map {
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  z-index: 98;
}
.margin-top-20 {
  margin-top: 2rem !important;
}
.home-wheel-rotate-view {
  width: 33rem;
  height: 2.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.home-wheel-rotate-view .wheel-rotate-left-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 2.1rem;
  background: #FFFFFF;
  border: 0.1rem solid #F5F5F5;
  border-radius: 0.4rem;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;
  color: #1A1A1A;
}
.home-wheel-rotate-view .wheel-rotate-center-view {
  width: 22.4rem;
  height: 0.9rem;
  position: relative;
}
.home-wheel-rotate-view .wheel-rotate-center-view .wheel-rotate-slider-view {
  width: 22.4rem;
  height: 0.9rem;
  /* Linear */
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border-radius: 22px;
}
.home-wheel-rotate-view .wheel-rotate-center-view .wheel-rotate-pos-view {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  left: 0%;
  top: -0.5rem;
  margin-left: -0.95rem;
  box-sizing: border-box;
  background: linear-gradient(92.4deg, #FF721E 1.2%, #FFB54A 99.25%);
  border: 0.2rem solid #FFF4EF;
  border-radius: 100%;
}
.home-wheel-rotate-view .wheel-rotate-right-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 2.1rem;
  background: #FFF4EF;
  border-radius: 0.4rem;
  box-sizing: border-box;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;
  color: #FF7116;
}
