.jkw-container-window {
  width: 100%;
  height: 100%;
  background-color: #eaeaea !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.jkw-container-window .jkw-main-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.jkw-container-window .jkw-main-window .jkw-down-window {
  margin-top: 0.2rem;
  width: 100%;
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
}
.font1 {
  color: #fafafa;
}
.margintop5 {
  margin-top: 0.5rem;
}
.margintop15 {
  margin-top: 0.5rem;
}
.jkw-icon-24 {
  width: 2.4rem;
  height: 2.4rem;
}
.jkw-icon-48 {
  width: 4.8rem;
  height: 4.8rem;
}
.jkw-icon-16 {
  width: auto;
  height: 1.6rem;
}
.jkw-icon-40 {
  width: 4rem;
  height: 4rem;
}
.jkw-icon-13 {
  width: 1.3rem;
  height: 1.3rem;
}
.jkw-icon-14 {
  width: 1.4rem;
  height: 1.4rem;
}
