.jkw-home-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.lv1-left-view {
  width: 1050px;
  height: 100%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lv1-left-view .lv1-left-top-view {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
}
.lv1-left-view .lv2-main-view {
  width: 100%;
  flex: 1;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.lv1-left-view .lv2-main-view .lv2-left-view {
  background-color: #fff;
  width: 573px;
  height: 536px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  margin-right: 20px;
}
.lv1-left-view .lv2-main-view .lv2-left-view .lv2-left-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 0.5008px solid rgba(237, 108, 0, 0.2);
}
.lv1-left-view .lv2-main-view .lv2-right-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lv1-left-view .lv2-main-view .lv2-right-view .lv2-right-top-view {
  background-color: #fff;
  width: 457px;
  height: 249px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
}
.lv1-left-view .lv2-main-view .lv2-right-view .lv2-right-top-view .lv2-right-top-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 0.5008px solid rgba(237, 108, 0, 0.2);
}
.lv1-left-view .lv2-main-view .lv2-right-view .lv2-right-dowm-view {
  margin-top: 20px;
  background-color: #fff;
  width: 457px;
  height: 267px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
}
.lv1-left-view .lv2-main-view .lv2-right-view .lv2-right-dowm-view .lv2-right-dowm-top-view {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 0.5008px solid rgba(237, 108, 0, 0.2);
}
.lv1-right-view {
  width: 225px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.lv1-right-view .lv1-right-top-view {
  width: 100%;
  height: 47px;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.jkw-game-pic-list-view {
  width: 220px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.jkw-game-pic-list-view .jkw-game-pic-1 {
  width: 222px;
  height: 150px;
  margin-bottom: 20px;
}
.jkw-font-1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.jkw-font-2 {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.9);
}
.jkw-btn-1 {
  padding: 5px 10px ;
  font-size: 12px;
  background: rgba(237, 108, 0, 0.05);
  color: rgba(237, 108, 0, 0.6);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.jkw-device-pic-1 {
  width: 242px;
  height: 328px;
  margin-top: 10px;
  margin-bottom: 7px;
}
.jkw-device-pic-2 {
  width: 151px;
  height: 89px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.jkw-device-pic-3 {
  width: 180px;
  height: 180px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.jkw-jizuo-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 2rem;
}
